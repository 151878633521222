/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import {
  adminDataInterface,
  AdminStatusEnum,
  SelectedAppointmentDto,
} from "./service/adminTypes";

export const changeDayReducer = (
  state: WritableDraft<adminDataInterface>,
  action: { payload: any; type?: string }
) => {
  state.appointments.dayIndex = action.payload;
  state.appointments.customerForm.date =
    state.appointments.appointmentsPerDays[
      state.appointments.dayIndex
    ].localDate;
  state.appointments.timeIndex = 0;
  state.appointments.customerForm.startTime =
    state.appointments.appointmentsPerDays[
      state.appointments.dayIndex
    ]?.appointmentSlotDtos[state.appointments.timeIndex]?.startTime;
  state.appointments.customerForm.endTime =
    state.appointments.appointmentsPerDays[
      state.appointments.dayIndex
    ]?.appointmentSlotDtos[state.appointments.timeIndex]?.endTime;
};

export const changeTimeReducer = (
  state: WritableDraft<adminDataInterface>,
  action: { payload: any; type?: string }
) => {
  state.appointments.timeIndex = action.payload;
  state.appointments.customerForm.startTime =
    state.appointments.appointmentsPerDays[
      state.appointments.dayIndex
    ]?.appointmentSlotDtos[state.appointments.timeIndex]?.startTime;
  state.appointments.customerForm.endTime =
    state.appointments.appointmentsPerDays[
      state.appointments.dayIndex
    ]?.appointmentSlotDtos[state.appointments.timeIndex]?.endTime;
};

export const loginAdminFulfilled = (
  state: WritableDraft<adminDataInterface>,
  action: PayloadAction<
    string,
    string,
    {
      arg: { username: string; password: string };
      requestId: string;
      requestStatus: "fulfilled";
    },
    never
  >
) => {
  if (!!action.payload) {
    state.admin.token = action.payload;
    state.admin.adminStatus = AdminStatusEnum.Logged;
  } else {
    state.admin.adminStatus = AdminStatusEnum.WrongCredentials;
  }
};

export const getAppointmentFulfilled = (
  state: WritableDraft<adminDataInterface>,
  action: PayloadAction<
    SelectedAppointmentDto | undefined,
    string,
    {
      arg: { adminToken: string; userToken: string };
      requestId: string;
      requestStatus: "fulfilled";
    },
    never
  >
) => {
  if (action.payload) {
    state.appointments.reservedAppointmentData.date = action.payload?.date;
    state.appointments.reservedAppointmentData.startTime =
      action.payload?.startTime;
    state.appointments.reservedAppointmentData.endTime =
      action.payload?.endTime;
    state.appointments.reservedAppointmentData.name = action.payload?.name;
    state.appointments.reservedAppointmentData.email = action.payload?.email;
    state.appointments.reservedAppointmentData.phone = action.payload?.phone;
  }
};
