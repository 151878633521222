/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import Heading3 from "../../common/text/Heading3";
import Text1 from "../../common/text/Text1";

const Footer = () => {
  return (
    <footer className="w-full mt-20 md:mt-24 bg-blackFlat flex justify-center flex-col items-center">
      <div className="w-full px-4 pt-16 max-w-container pb-[80px] desktop:pb-[96px] desktop:grid desktop:grid-cols-2 desktop:mb-4 desktop:pt-[88px]">
        <div>
          <a href="geo:44.880906,17.6710207?q=44.880906,17.6710207(Teniski klub Maksim)">
            <Heading3 className="text-white uppercase" position="text-left">
              Adresa:
            </Heading3>
            <Text1 className="mt-4 text-white">Teniski klub “Maksim”</Text1>
            <Text1 className="mt-2 text-white">Ulica Vlade Vinčića</Text1>
            <Text1 className="mt-2 text-white ">Prnjavor 78430</Text1>
          </a>
        </div>

        <div>
          <Heading3
            className="mt-12 text-white desktop:mt-0 uppercase"
            position="text-left"
          >
            Radno vrijeme:
          </Heading3>
          <Text1 className="mt-4 text-white">
            Ponedjeljak - Subota: 08h - 22h
          </Text1>
          <Text1 className="mt-2 text-white">Nedjelja: Neradna</Text1>
        </div>
        <div>
          <Heading3 className="mt-12 text-white uppercase" position="text-left">
            Kontakt:
          </Heading3>
          <Text1 className="mt-4 text-white">Teniski klub “Maksim”</Text1>
          <Text1 className="mt-2 text-white">
            <a href="tel:+38765419338">+387 65 419 338</a>
          </Text1>
          <Text1 className="mt-2 text-white">
            <a href="mailto:tkmaksimprnjavor@gmail.com">
              tkmaksimprnjavor@gmail.com
            </a>
          </Text1>
          <div className="flex mt-4 text-2xl ">
            <a
              href="https://www.facebook.com/tenismaksim/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center justify-center w-8 h-8 mr-2 p-[7px] bg-white rounded-full">
                <BsFacebook accentHeight={23} />
              </div>
            </a>
            <a
              href="https://www.instagram.com/tk_maksim_prnjavor/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center justify-center w-8 h-8 mx-2 p-[7px] bg-white rounded-full">
                <AiOutlineInstagram />
              </div>
            </a>
            <a
              href="https://www.youtube.com/channel/UCAtb67VNHIdlpwWWfH-cVeQ"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center justify-center w-8 h-8 mx-2 p-[7px] bg-white rounded-full">
                <AiFillYoutube />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full h-12 bg-greyLight">
        <Text1>© 2022 by Digital Partner</Text1>
      </div>
    </footer>
  );
};

export default Footer;
