import AppointmentFormState from "./AppointmentFormState";
import { PayloadAction } from "@reduxjs/toolkit";

export default function selectTime(
  state: AppointmentFormState,
  action: PayloadAction<string>
) {
  state.selectedServiceRoom = null;
  state.selectedTime = action.payload;
}
