import { SalonOrderDto } from "../../../service/customerTypes";
import SessionState from "./SessionState";
import { saveCurrentAppointment as saveCurrentAppointmentToLocalStorage } from "./currentAppointmentStorage";

export default function saveCurrentAppointment(
  state: SessionState,
  orderAppointment: SalonOrderDto
) {
  state.currentAppointment = orderAppointment;
  saveCurrentAppointmentToLocalStorage(orderAppointment);
}
