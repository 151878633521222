// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_SAMPLE_RATE = parseFloat(
  process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || 1.0
);

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://9500de1f640842eb876633af53cddd09@o1345536.ingest.sentry.io/6622221",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
