import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "../store";
import {
  adminDataInterface,
  EmployeeReadOrdersDto,
  OrderToShowDto,
} from "./service/adminTypes";
import adminService from "./service/adminServices";

const fetchOrdersAsync = createAsyncThunk<
  EmployeeReadOrdersDto | null,
  { forceFetch?: boolean } | undefined,
  { state: AppState }
>("adminData/fetchOrdersAsync", async (undefined, thunkApi) => {
  const adminData: adminDataInterface = thunkApi.getState().adminData;
  const salonOrders = adminData.salonOrders;

  if (
    salonOrders.orderFetchingId == thunkApi.requestId &&
    salonOrders.selectedDay
  ) {
    const authToken = adminData.admin.token;

    if (authToken) {
      const response = await adminService.getOrdersForDay(
        authToken,
        salonOrders.selectedDay
      );
      if (response.status == 200) {
        return response.data;
      } else {
        console.error(
          "adminData/fetchOrdersAsync response status: ",
          response.status
        );
      }
    } else {
      console.error("adminData/fetchOrdersAsync: session not started");
    }
  }
  return null;
});

export default fetchOrdersAsync;

export function applyFetchOrdersAsyncExtraReducers(
  builder: ActionReducerMapBuilder<adminDataInterface>
) {
  builder.addCase(fetchOrdersAsync.pending, (state, action) => {
    const forceFetchArg = action.meta.arg?.forceFetch;

    const shouldForceFetch =
      forceFetchArg == true &&
      state.salonOrders.orderFetchingStatus != "FETCHING";

    if (
      state.salonOrders.orderFetchingStatus == "NOT_FETCHED" ||
      shouldForceFetch
    ) {
      state.salonOrders.orderFetchingStatus = "FETCHING";
      state.salonOrders.orderFetchingId = action.meta.requestId;
    }
  });

  builder.addCase(fetchOrdersAsync.fulfilled, (state, action) => {
    if (state.salonOrders.orderFetchingId == action.meta.requestId) {
      state.salonOrders.orderFetchingStatus =
        action.payload == null ? "NOT_FETCHED" : "FETCHED";
      state.salonOrders.orderFetchingId = null;
      state.salonOrders.orders = action.payload;
      if (state.salonOrders.selectedOrder != null) {
        state.salonOrders.selectedOrder = reselectSelectedOrderAfterUpdate(
          state.salonOrders.selectedOrder,
          state.salonOrders.orders
        );
      }
    }
  });

  builder.addCase(fetchOrdersAsync.rejected, (state, action) => {
    if (state.salonOrders.orderFetchingId == action.meta.requestId) {
      state.salonOrders.orderFetchingStatus = "NOT_FETCHED";
      state.salonOrders.orderFetchingId = null;
    }
  });
}

function reselectSelectedOrderAfterUpdate(
  oldSelectedOrder: OrderToShowDto,
  orders: EmployeeReadOrdersDto | null
) {
  if (orders == null) {
    return null;
  }
  const newSelected = orders.orders.find(
    (order) => order.salonOrderId == oldSelectedOrder.salonOrderId
  );
  return newSelected === undefined ? null : newSelected;
}
