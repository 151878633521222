import { createSlice } from "@reduxjs/toolkit";
import CustomerSliceState, { createInitialState } from "./CustomerSliceState";
import { reducers as appointmentFormReducers } from "./appointmnent-form";
import { reducers as sessionReducers } from "./session";
import sessionExtraReducers from "./sessionExtraReducers";
import makeAppointmentExtraReducers from "./appointmnent-form/makeAppointment/makeAppointmentExtraReducers";
import { applyFetchServicesAsyncExtraReducers } from "./salon-services/fetchServicesAsync";
import { applyFetchAvailableAppointmentsAsyncIfNeededExtraReducers } from "./available-appointments/fetchAvailableAppointmentsAsyncIfNeeded";
import { applyStartAdminPanelCustomerSessionExtraReducers } from "./session/startCustomerSessionFromAdminPanel";

const initialState: CustomerSliceState = createInitialState();

export const SLICE_NAME = "customer-client";

export const customerClientSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...appointmentFormReducers,
    ...sessionReducers,
  },
  extraReducers: (builder) => {
    sessionExtraReducers(builder);
    applyFetchAvailableAppointmentsAsyncIfNeededExtraReducers(builder);
    makeAppointmentExtraReducers(builder);
    applyFetchServicesAsyncExtraReducers(builder);
    applyStartAdminPanelCustomerSessionExtraReducers(builder);
  },
});

export const {
  changeEmail,
  changeName,
  changePhone,
  changeManualPrice,
  selectDay,
  selectService,
  selectServiceRoom,
  selectTime,
  selectAppointment,
  removeCurrentAppointment,
} = customerClientSlice.actions;

export default customerClientSlice.reducer;
