import React from "react";
import useTrackCtaAppointments from "../../../../analytics-hooks/useTrackCtaAppointments";
import NavbarMenuItem from "./NavbarMenuItem";

const NavbarMenu: React.FC = () => {
  const dispatchCTAAppointment = useTrackCtaAppointments();

  return (
    <div className="hidden mr-14 desktop:inline-flex">
      <NavbarMenuItem href="/">Početna</NavbarMenuItem>
      <NavbarMenuItem href="/skola-tenisa">Škola tenisa</NavbarMenuItem>
      <NavbarMenuItem href="/kontakt">Kontakt</NavbarMenuItem>
      <NavbarMenuItem href="/podrzite-nas">Podržite nas</NavbarMenuItem>
      <NavbarMenuItem
        href="/zakazi-termin"
        onClick={() => dispatchCTAAppointment()}
      >
        Zakaži termin
      </NavbarMenuItem>
    </div>
  );
};

export default NavbarMenu;
