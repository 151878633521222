import {
  configureStore,
  ThunkAction,
  Action,
  EnhancedStore,
  AnyAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { useMemo } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { adminDataInterface } from "./admin/service/adminTypes";
import customerClientSlice from "./rendezvous-customer-redux/_internal/customer-slice/customerSlice";
import CustomerSliceState from "./rendezvous-customer-redux/_internal/customer-slice/CustomerSliceState";
import adminDataSlice from "./admin/adminDataSlice";

interface StoreType {
  customerClient: CustomerSliceState;
  adminData: adminDataInterface;
}

function makeStore(): EnhancedStore<StoreType, AnyAction> {
  return configureStore({
    reducer: {
      customerClient: customerClientSlice,
      adminData: adminDataSlice,
    },
  });
}

let store: EnhancedStore<StoreType, AnyAction>;

const initializeStore = (): EnhancedStore<StoreType, AnyAction> => {
  const _store = store ?? makeStore();

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export type AppState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  StoreType,
  Action<string>
>;

export function useStore(): EnhancedStore<StoreType, AnyAction> {
  const store = useMemo(() => initializeStore(), []);
  return store;
}

export type ThunkAppDispatch = ThunkDispatch<AppState, void, Action>;

export const useAppDispatch = () => useDispatch<ThunkAppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default useStore;
