import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "../../../../store";
import customerService from "../../../service/customerService";
import { AvailableSalonServicesDto } from "../../../service/customerTypes";
import CustomerSliceState from "../CustomerSliceState";

const fetchServicesAsync = createAsyncThunk<
  AvailableSalonServicesDto | null,
  void,
  { state: AppState }
>("customer-client/fetchServicesAsync", async (fetchRequestId, thunkApi) => {
  const customerClientState = thunkApi.getState().customerClient;
  const availableSalonServices = customerClientState.availableSalonServices;

  if (availableSalonServices.fetchingRequestId == thunkApi.requestId) {
    const userToken = customerClientState.sessionState.userToken;

    if (userToken) {
      const response = await customerService.getServices(userToken);
      if (response.status == 200) {
        return response.data;
      } else {
        console.error(
          "customer-client/fetchServicesAsync response status: ",
          response.status
        );
      }
    } else {
      console.error("customer-client/fetchServicesAsync: session not started");
    }
  }
  return null;
});

export default fetchServicesAsync;

export function applyFetchServicesAsyncExtraReducers(
  builder: ActionReducerMapBuilder<CustomerSliceState>
) {
  builder.addCase(fetchServicesAsync.pending, (state, payload) => {
    if (state.availableSalonServices.fetchingStatus == "NOT_FETCHED") {
      state.availableSalonServices.fetchingStatus = "FETCHING";
      state.availableSalonServices.fetchingRequestId = payload.meta.requestId;
    }
  });

  builder.addCase(fetchServicesAsync.fulfilled, (state, action) => {
    if (
      state.availableSalonServices.fetchingRequestId == action.meta.requestId
    ) {
      state.availableSalonServices.fetchingStatus =
        action.payload == null ? "NOT_FETCHED" : "FETCHED";
      state.availableSalonServices.fetchingRequestId = null;
      state.availableSalonServices.services = action.payload;
    }
  });

  builder.addCase(fetchServicesAsync.rejected, (state, action) => {
    if (
      state.availableSalonServices.fetchingRequestId == action.meta.requestId
    ) {
      state.availableSalonServices.fetchingStatus = "NOT_FETCHED";
      state.availableSalonServices.fetchingRequestId = null;
    }
  });
}
