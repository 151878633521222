import customerService from "../../../service/customerService";
import UserTokenStorage from "./UserTokenStorage";

const generateTokenSession = async (): Promise<string> => {
  let newToken: string;
  let attempts = 0;
  const maxAttempts = 5;

  while (attempts++ < maxAttempts) {
    try {
      const result = await customerService.registerAnonymous();
      newToken = result.data.token;
      attempts = maxAttempts;
      return newToken;
    } catch (error) {}
  }

  console.error(
    "generateTokenSession was not able to obtain token from server"
  );
  throw new Error();
};

export default generateTokenSession;
