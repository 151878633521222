import { createAsyncThunk } from "@reduxjs/toolkit";
import adminServices from "../service/adminServices";

export const getAllAppointments = createAsyncThunk(
  "admin/getAllAppointments",
  async (token: string) => {
    try {
      const data = await adminServices.getAppointmentCalendar(token);
      return data.appointmentsPerDays;
    } catch (err) {
      throw new Error();
    }
  }
);
