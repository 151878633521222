import { SalonOrderDto } from "./../../../service/customerTypes";
import { AppState } from "../../../../store";

export const hasCurrentAppointmentSelecor = (state: AppState): boolean => {
  const currentDate = new Date();
  const currentAppointment =
    state.customerClient.sessionState.currentAppointment;

  return (
    currentAppointment != null &&
    currentAppointment.orderItems.some(
      (orderItem) =>
        new Date(orderItem.endTime).getTime() > currentDate.getTime()
    )
  );
};

export const currentAppointmentSelector = (
  state: AppState
): SalonOrderDto | null => {
  return hasCurrentAppointmentSelecor(state)
    ? state.customerClient.sessionState.currentAppointment
    : null;
};
