import { useEffect } from "react";
import { hotjar } from "react-hotjar";

const useHotjar = () => {
  const HOTJAR_ID =
    process.env.NEXT_PUBLIC_HOTJAR_ID !== undefined
      ? parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID)
      : -1;

  function InitializeHotjar() {
    useEffect(() => {
      if (HOTJAR_ID != -1) {
        hotjar.initialize(HOTJAR_ID, 6);
      }
    }, []);
    return null;
  }

  return InitializeHotjar;
};

export default useHotjar;
