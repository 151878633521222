export interface adminDataInterface {
  admin: {
    token: string;
    adminStatus: AdminStatusEnum;
  };
  appointments: {
    appointmentsPerDays: [] | AppointmentsPerDaysDto[];
    loadingAppointmentData: boolean;
    dayIndex: number;
    timeIndex: number;
    customerForm: SelectedAppointmentDto;
    reservedAppointmentData: SelectedAppointmentDto;
  };
  salonOrders: {
    selectedDay: string | null;
    selectedAppointmentTime: string | null;
    selectedOrder: OrderToShowDto | null;
    orderFetchingStatus: "NOT_FETCHED" | "FETCHING" | "FETCHED";
    orderFetchingId: string | null;
    orders: EmployeeReadOrdersDto | null;
  };
}

export enum AdminStatusEnum {
  NotChecked = "NotChecked",
  Logged = "Logged",
  WrongCredentials = "WrongCredentials",
}
export interface EmployeeAppointmentCalendarDto {
  appointmentsPerDays: AppointmentsPerDaysDto[] | [];
}

export interface AppointmentsPerDaysDto {
  localDate: string;
  appointmentSlotDtos: EmployeeAppointmentSlotDto[];
}

export enum AdminAppointmentTimeTypeEnum {
  DISABLED = "DISABLED",
  AVAILABLE = "AVAILABLE",
  OCCUPIED = "OCCUPIED",
}

export interface EmployeeAppointmentSlotDto {
  startTime: string;
  endTime: string;
  appointmentContractId: string;
  appointmentSlotStatus: AdminAppointmentTimeTypeEnum;
}

export interface SelectedAppointmentDto {
  date: string;
  startTime: string;
  endTime: string;
  name: string;
  phone: string;
  email: string;
}

export interface EmployeeReadOrdersDto {
  employees: EmployeeToShowDto[];
  customers: CustomerToShowDto[];
  serviceRooms: ServiceRoomToShowDto[];
  services: ServiceToShowDto[];
  orders: OrderToShowDto[];
}

export interface EmployeeToShowDto {
  salonEmployeeId: string;
  name: string;
}

export interface CustomerToShowDto {
  salonCustomerId: string;
  name: string;
  phone: string;
  email: string;
}

export interface ServiceRoomToShowDto {
  salonServiceRoomId: string;
  name: string;
}

export interface ServiceToShowDto {
  salonServiceId: string;
  category: string;
  name: string;
  durationInMinutes: string;
  price: number;
}

export interface OrderToShowDto {
  salonOrderId: string;
  orderItems: OrderItemToShowDto[];
  price: number | null;
  note: string | null;
}

export interface OrderItemToShowDto {
  salonOrderId: string;
  salonCustomerId: string;
  salonServiceRoomId: string;
  salonServiceId: string;
  salonEmployeeId: string;
  startTime: string;
  endTime: string;
  isCanceled: boolean;
}

export interface ManualPriceDto {
  price: number;
}

export interface UpdateNoteDto {
  note: string;
}
