import React from "react";
import Footer from "./footer/Footer";
// import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <>
      <Navbar />
      <main className="mt-16 desktop:mt-[87px] bg-white ">
        {props.children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
