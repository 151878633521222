import classNames from "classnames";
import React, { ReactNode } from "react";

interface Text1Props {
  children: ReactNode;
  weight?: string;
  className?: string;
}

const Text1: React.FC<Text1Props> = (props) => {
  const weight = props.weight ? props.weight : "font-light";

  const className = classNames(
    "text-lg leading-6 font-roboto desktop:text-lg",
    props.className,
    weight
  );
  return <p className={className}>{props.children}</p>;
};

export default Text1;
