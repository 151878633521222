import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "../../../../store";
import customerService from "../../../service/customerService";
import { AvailableAppointmentsForServicesDto } from "../../../service/customerTypes";
import CustomerSliceState from "../CustomerSliceState";
import { getAvailableAppotinments } from "./AvailableAppointmentsState";

export const fetchAvailableAppointmentsAsyncIfNeeded = createAsyncThunk<
  AvailableAppointmentsForServicesDto | null,
  string,
  { state: AppState }
>(
  `customer-client/fetchAvailableAppointmentsAsyncIfNeeded`,
  async (serviceId: string, thunkApi) => {
    const customerClientState: CustomerSliceState =
      thunkApi.getState().customerClient;

    const availableAppointmentsCache =
      customerClientState.availableAppointments[serviceId];

    if (
      availableAppointmentsCache &&
      availableAppointmentsCache.fetchingRequestId == thunkApi.requestId
    ) {
      const userToken = customerClientState.sessionState.userToken;

      if (userToken) {
        const response =
          await customerService.getAvailableAppointmentsForServices(userToken, [
            serviceId,
          ]);

        if (response.status == 200) {
          return response.data;
        } else {
          console.error(
            "customer-client/fetchAvailableAppointmentsAsyncIfNeeded response status: ",
            response.status
          );
        }
      } else {
        console.error(
          "customer-client/fetchAvailableAppointmentsAsyncIfNeeded: session not started"
        );
      }
    }

    return null;
  }
);

export function applyFetchAvailableAppointmentsAsyncIfNeededExtraReducers(
  builder: ActionReducerMapBuilder<CustomerSliceState>
) {
  builder.addCase(
    fetchAvailableAppointmentsAsyncIfNeeded.pending,
    (state, action) => {
      const cache = getAvailableAppotinments(
        state.availableAppointments,
        action.meta.arg
      );

      if (cache.fetchingStatus == "NOT_FETCHED") {
        cache.fetchingStatus = "FETCHING";
        cache.fetchingRequestId = action.meta.requestId;
      }
    }
  );

  builder.addCase(
    fetchAvailableAppointmentsAsyncIfNeeded.fulfilled,
    (state, action) => {
      const cache = getAvailableAppotinments(
        state.availableAppointments,
        action.meta.arg
      );

      if (cache.fetchingRequestId == action.meta.requestId) {
        cache.fetchingStatus =
          action.payload == null ? "NOT_FETCHED" : "FETCHED";
        cache.fetchingRequestId = null;
        cache.appointments = action.payload;
      }
    }
  );

  builder.addCase(
    fetchAvailableAppointmentsAsyncIfNeeded.rejected,
    (state, action) => {
      const cache = getAvailableAppotinments(
        state.availableAppointments,
        action.meta.arg
      );

      if (cache.fetchingRequestId == action.meta.requestId) {
        cache.fetchingStatus = "NOT_FETCHED";
        cache.fetchingRequestId = null;
      }
    }
  );
}

export default fetchAvailableAppointmentsAsyncIfNeeded;
