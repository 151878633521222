export default interface CurrentAppointmentState {
  date: string | null;
  time: string | null;
  name: string | null;
  phone: string | null;
  email: string | null;
}

export function createInitialCurrentAppointmentState(): CurrentAppointmentState {
  return { date: null, time: null, name: null, phone: null, email: null };
}
