import classNames from "classnames";
import React, { ReactNode } from "react";

interface Heading3Props {
  children: ReactNode;
  className?: string;
  position?: "text-center" | "text-left";
}

const Heading3: React.FC<Heading3Props> = (props) => {
  const position = props.position ? props.position : "text-center";
  const className = classNames(
    "text-2xl leading-[32px] font-roboto-condensed font-bold",
    props.className,
    position
  );
  return <h3 className={className}>{props.children}</h3>;
};

export default Heading3;
