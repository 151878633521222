import selectDay from "./selectDay";
import selectTime from "./selectTime";
import { PayloadAction, Draft } from "@reduxjs/toolkit";
import CustomerSliceState from "../CustomerSliceState";
import changeName from "./changeName";
import changeEmail from "./changeEmail";
import changePhone from "./changePhone";
import selectService from "./selectService";
import {
  OrderAvailableAppointmentDto,
  SalonServiceDto,
} from "../../../service/customerTypes";
import selectServiceRoom from "./selectServiceRoom";
import selectAppointment from "./selectAppointment";
import changeManualPrice from "./changeManualPrice";

export const reducers = {
  selectDay: (
    state: Draft<CustomerSliceState>,
    action: PayloadAction<string>
  ) => selectDay(state.appointmentForm, action),
  selectTime: (
    state: Draft<CustomerSliceState>,
    action: PayloadAction<string>
  ) => selectTime(state.appointmentForm, action),
  selectAppointment: (
    state: Draft<CustomerSliceState>,
    action: PayloadAction<OrderAvailableAppointmentDto>
  ) => selectAppointment(state.appointmentForm, action),
  selectService: (
    state: Draft<CustomerSliceState>,
    action: PayloadAction<SalonServiceDto>
  ) => selectService(state.appointmentForm, action),
  selectServiceRoom: (
    state: Draft<CustomerSliceState>,
    action: PayloadAction<string>
  ) => selectServiceRoom(state.appointmentForm, action),
  changeName: (
    state: Draft<CustomerSliceState>,
    action: PayloadAction<string>
  ) => changeName(state.appointmentForm, action),
  changeEmail: (
    state: Draft<CustomerSliceState>,
    action: PayloadAction<string>
  ) => changeEmail(state.appointmentForm, action),
  changePhone: (
    state: Draft<CustomerSliceState>,
    action: PayloadAction<string>
  ) => changePhone(state.appointmentForm, action),
  changeManualPrice: (
    state: Draft<CustomerSliceState>,
    action: PayloadAction<number>
  ) => changeManualPrice(state.appointmentForm, action),
};
