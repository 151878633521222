import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import CustomerSliceState, { resetStateToInitial } from "../CustomerSliceState";

import generateTokenSession from "./generateTokenSession";
import SessionStatus from "./SessionStatus";

export const startAdminPanelCustomerSession = createAsyncThunk(
  `customer-client/startAdminPanelCustomerSession`,
  async () => {
    const token = await generateTokenSession();

    return token;
  }
);

export function applyStartAdminPanelCustomerSessionExtraReducers(
  builder: ActionReducerMapBuilder<CustomerSliceState>
) {
  builder.addCase(startAdminPanelCustomerSession.pending, (state) => {
    resetStateToInitial(state);
    state.sessionState.userToken = null;
    state.sessionState.currentAppointment = null;
    state.sessionState.sessionStatus = SessionStatus.Starting;
  });

  builder.addCase(
    startAdminPanelCustomerSession.fulfilled,
    (state: CustomerSliceState, { payload }) => {
      state.sessionState.userToken = payload;
      state.sessionState.sessionStatus = SessionStatus.Started;
    }
  );

  builder.addCase(
    startAdminPanelCustomerSession.rejected,
    (state: CustomerSliceState) => {
      state.sessionState.userToken = null;
      state.sessionState.currentAppointment = null;
      state.sessionState.sessionStatus = SessionStatus.Failed;
    }
  );
}

export default startAdminPanelCustomerSession;
