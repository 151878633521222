import { createAsyncThunk } from "@reduxjs/toolkit";
import UserTokenStorage from "./UserTokenStorage";

import customerService from "../../../service/customerService";
import generateTokenSession from "./generateTokenSession";
import { AppState } from "../../../../store";

export const startCustomerSession = createAsyncThunk<
  string | null,
  void,
  { state: AppState }
>(`customer-client/startCustomerSession`, async (undefined, thunkApi) => {
  const customerClientState = thunkApi.getState().customerClient;
  const sessionState = customerClientState.sessionState;

  const tokenStorage = new UserTokenStorage();

  let token: string | null = null;

  if (sessionState.sessiontStatusRequestId == thunkApi.requestId) {
    token = getTokenFromStorage(tokenStorage);

    if (!(await checkIsTokenValidOnServer(token))) {
      token = null;
    }

    if (!token) {
      token = await generateTokenAndSaveItToLocalStorage(tokenStorage);
    }
  }

  return token;
});

function getTokenFromStorage(tokenStorage: UserTokenStorage): string | null {
  let token = null;
  try {
    token = tokenStorage.getToken();
  } catch (error) {
    console.error("Cannot get token from localStorage");
    console.error(error);
  }
  return token;
}

async function checkIsTokenValidOnServer(
  token: string | null
): Promise<boolean> {
  let isValid = false;
  if (token) {
    try {
      const res = await customerService.getCustomer(token);
      if (res.status === 200) {
        isValid = true;
      }
    } catch (error) {}
  }
  return isValid;
}

async function generateTokenAndSaveItToLocalStorage(
  tokenStorage: UserTokenStorage
): Promise<string> {
  const token = await generateTokenSession();
  try {
    tokenStorage.setToken(token);
  } catch (error) {
    console.error("Cannot save token to localStorage");
    console.error(error);
  }
  return token;
}
