import { SalonOrderDto } from "../../../service/customerTypes";

const STORAGE_KEY = "currentAppointment";

export function saveCurrentAppointment(orderAppointment: SalonOrderDto) {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(orderAppointment));
  } catch (error) {
    console.error("Cannot save current appointment to localstorage", error);
  }
}

export function loadCurrentAppointment(): SalonOrderDto | null {
  try {
    const json = localStorage.getItem(STORAGE_KEY);
    if (json) {
      return JSON.parse(json);
    }
  } catch (error) {
    console.error("Cannot load current appointment from localstorage", error);
  }
  return null;
}

export function removeCurrentAppointment(): void {
  try {
    localStorage.removeItem(STORAGE_KEY);
  } catch (error) {
    console.error("Cannot remove current appointment from localstorage", error);
  }
}
