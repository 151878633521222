import AppointmentFormState from "./AppointmentFormState";
import { PayloadAction } from "@reduxjs/toolkit";

export default function selectDay(
  state: AppointmentFormState,
  action: PayloadAction<string>
) {
  state.selectedDay = action.payload;
  state.selectedTime = null;
  state.selectedServiceRoom = null;
}
