import { createSlice } from "@reduxjs/toolkit";
import router from "next/router";
import { adminDataInterface, AdminStatusEnum } from "./service/adminTypes";
import {
  changeDayReducer,
  changeTimeReducer,
  getAppointmentFulfilled,
  loginAdminFulfilled,
} from "./adminDataReducers";
import { getAllAppointments } from "./extraReducers/getAllAppointments";
import { getAppointment } from "./extraReducers/getAppointment";
import { loginAdmin } from "./extraReducers/loginAdmin";
import { applyFetchOrdersAsyncExtraReducers } from "./fetchOrdersAsync";
import { applyCancelSelectedOrderReducers } from "./cancelSelectedOrder";

const initialState: adminDataInterface = {
  admin: {
    token: "",
    adminStatus: AdminStatusEnum.NotChecked,
  },
  appointments: {
    appointmentsPerDays: [],
    loadingAppointmentData: true,
    dayIndex: 0,
    timeIndex: 0,
    customerForm: {
      date: "",
      startTime: "",
      endTime: "",
      name: "",
      phone: "",
      email: "",
    },
    reservedAppointmentData: {
      date: "",
      startTime: "",
      endTime: "",
      name: "",
      phone: "",
      email: "",
    },
  },
  salonOrders: {
    selectedDay: null,
    selectedAppointmentTime: null,
    selectedOrder: null,
    orderFetchingStatus: "NOT_FETCHED",
    orders: null,
    orderFetchingId: null,
  },
};

export const adminDataSlice = createSlice({
  name: "adminData",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.admin.token = action.payload;
    },
    changeDay: (state, action) => {
      changeDayReducer(state, action);
    },
    changeTime: (state, action) => {
      changeTimeReducer(state, action);
    },
    changeName: (state, action) => {
      state.appointments.customerForm.name = action.payload;
    },
    changePhone: (state, action) => {
      state.appointments.customerForm.phone = action.payload;
    },
    changeEmail: (state, action) => {
      state.appointments.customerForm.email = action.payload;
    },
    changeAdminToken: (state, action) => {
      state.admin.token = action.payload;
    },
    selectDay: (state, action) => {
      if (state.salonOrders.orderFetchingStatus != "FETCHING") {
        state.salonOrders.selectedDay = action.payload;
        state.salonOrders.orderFetchingStatus = "NOT_FETCHED";
        state.salonOrders.orders = null;
        state.salonOrders.selectedAppointmentTime = null;
        state.salonOrders.selectedOrder = null;
      }
    },
    selectAppointmentTime: (state, action) => {
      state.salonOrders.selectedAppointmentTime = action.payload;
      state.salonOrders.selectedOrder = null;
    },
    selectOrder: (state, action) => {
      state.salonOrders.selectedOrder = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loginAdmin.fulfilled, (state, action) => {
      loginAdminFulfilled(state, action);
    });
    builder.addCase(getAllAppointments.pending, (state) => {
      state.appointments.loadingAppointmentData = true;
    });
    builder.addCase(getAllAppointments.fulfilled, (state, action) => {
      state.appointments.appointmentsPerDays = action.payload;
      state.appointments.loadingAppointmentData = false;
    });
    builder.addCase(getAllAppointments.rejected, () => {
      localStorage.removeItem("adminToken");
      router.push("/login");
    });

    builder.addCase(getAppointment.fulfilled, (state, action) => {
      getAppointmentFulfilled(state, action);
    });

    applyFetchOrdersAsyncExtraReducers(builder);
    applyCancelSelectedOrderReducers(builder);
  },
});

export const {
  setToken,
  changeDay,
  changeTime,
  changeName,
  changePhone,
  changeEmail,
  changeAdminToken,
  selectDay,
  selectAppointmentTime,
  selectOrder,
} = adminDataSlice.actions;

export default adminDataSlice.reducer;
