import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { SalonOrderDto } from "../../../../service/customerTypes";
import CustomerSliceState from "../../CustomerSliceState";
import saveCurrentAppointment from "../../session/saveCurrentAppointment";
import makeAppointment from "./makeAppointment";

const makeAppointmentExtraReducers = (
  builder: ActionReducerMapBuilder<CustomerSliceState>
) => {
  builder.addCase(makeAppointment.pending, (state: CustomerSliceState) => {
    state.appointmentForm.makingAppointment = true;
  });

  builder.addCase(
    makeAppointment.fulfilled,
    (state: CustomerSliceState, action: { payload: SalonOrderDto | null }) => {
      state.appointmentForm.makingAppointment = false;

      if (action.payload) {
        saveCurrentAppointment(state.sessionState, action.payload);
      }
    }
  );

  builder.addCase(makeAppointment.rejected, (state: CustomerSliceState) => {
    state.appointmentForm.makingAppointment = false;
  });
};

export default makeAppointmentExtraReducers;
