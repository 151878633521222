import SessionState, {
  createInitisalSessionState,
} from "./session/SessionState";
import CurrentAppointmentState, {
  createInitialCurrentAppointmentState,
} from "./current-appointment/CurrentAppointmentState";
import AvailableAppointmentsState, {
  createInitialAvailableAppointmentsState,
} from "./available-appointments/AvailableAppointmentsState";
import AppointmentFormState, {
  createInitialAppointmentFormState,
} from "./appointmnent-form/AppointmentFormState";
import SalonServicesState, {
  createInitialSalonServicesState,
} from "./salon-services/SalonServiceState";
import { WritableDraft } from "immer/dist/internal";

export interface CustomerSliceState {
  sessionState: SessionState;
  currentAppointment: CurrentAppointmentState;
  availableAppointments: AvailableAppointmentsState;
  appointmentForm: AppointmentFormState;
  availableSalonServices: SalonServicesState;
}

export function createInitialState(): CustomerSliceState {
  return {
    sessionState: createInitisalSessionState(),
    currentAppointment: createInitialCurrentAppointmentState(),
    availableAppointments: createInitialAvailableAppointmentsState(),
    appointmentForm: createInitialAppointmentFormState(),
    availableSalonServices: createInitialSalonServicesState(),
  };
}

export function resetStateToInitial(state: WritableDraft<CustomerSliceState>) {
  state.sessionState = createInitisalSessionState();
  state.currentAppointment = createInitialCurrentAppointmentState();
  state.availableAppointments = createInitialAvailableAppointmentsState();
  state.appointmentForm = createInitialAppointmentFormState();
  state.availableSalonServices = createInitialSalonServicesState();
}

export default CustomerSliceState;
