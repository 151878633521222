import {
  OrderAvailableAppointmentDto,
  SalonServiceDto,
} from "../../../service/customerTypes";

export default interface AppointmentFormState {
  selectedService: SalonServiceDto | null;
  selectedDay: string | null;
  selectedTime: string | null;
  selectedServiceRoom: string | null;
  selectedAppointment: OrderAvailableAppointmentDto | null;
  name: string;
  email: string;
  phone: string;
  manualPrice: number | null;
  makingAppointment: boolean;
}

export function createInitialAppointmentFormState(): AppointmentFormState {
  return {
    selectedService: null,
    selectedDay: null,
    selectedTime: null,
    selectedServiceRoom: null,
    selectedAppointment: null,
    name: "",
    email: "",
    phone: "",
    makingAppointment: false,
    manualPrice: null,
  };
}
