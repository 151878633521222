import { AvailableAppointmentsForServicesDto } from "../../../service/customerTypes";

export default interface AvailableAppointmentsState {
  [key: string]: AvailableAppointmentsForService | undefined;
}

export interface AvailableAppointmentsForService {
  serviceId: string;
  appointments: AvailableAppointmentsForServicesDto | null;
  fetchingStatus: "NOT_FETCHED" | "FETCHING" | "FETCHED";
  fetchingRequestId: string | null;
}

export function getAvailableAppotinments(
  state: AvailableAppointmentsState,
  serviceId: string
): AvailableAppointmentsForService {
  let fromState = state[serviceId];
  if (fromState == undefined) {
    fromState = {
      serviceId: serviceId,
      appointments: null,
      fetchingStatus: "NOT_FETCHED",
      fetchingRequestId: null,
    };
    state[serviceId] = fromState;
  }
  return fromState;
}

export function createInitialAvailableAppointmentsState(): AvailableAppointmentsState {
  return {};
}
