export default class UserTokenStorage {
  getToken(): string | null {
    return localStorage.getItem("userToken");
  }
  setToken(token: string): void {
    localStorage.setItem("userToken", token);
  }
  clearToken(): void {
    localStorage.removeItem("userToken");
  }
}
