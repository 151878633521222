import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { adminDataInterface } from "./service/adminTypes";
import adminService from "./service/adminServices";
import fetchOrdersAsync from "./fetchOrdersAsync";

const cancelSelectedOrder = createAsyncThunk<void, void, { state: AppState }>(
  "adminData/cancelSelectedOrder",
  async (undefined, thunkApi) => {
    const adminData: adminDataInterface = thunkApi.getState().adminData;

    const salonOrderId = adminData.salonOrders.selectedOrder?.salonOrderId;
    const authToken = adminData.admin.token;

    if (salonOrderId && authToken) {
      await adminService.cancelOrder(authToken, salonOrderId);
      thunkApi.dispatch(fetchOrdersAsync({ forceFetch: true }));
    }

    return;
  }
);

export function applyCancelSelectedOrderReducers(
  builder: ActionReducerMapBuilder<adminDataInterface>
) {
  builder.addCase(cancelSelectedOrder.fulfilled, (state) => {
    state.salonOrders.selectedOrder = null;
  });
}

export default cancelSelectedOrder;
