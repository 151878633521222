import React from "react";
import Image from "next/image";
import logo from "../../../public/img/logo.png";
import { useIsPhone } from "../../common/hooks/useMediaQuery";
import Link from "next/link";

const Logo = () => {
  const isPhone = useIsPhone();
  const width = isPhone ? 139 : 179;
  const height = isPhone ? 40 : 52;
  return (
    <div className="items-center ml-4 desktop:ml-6">
      <Link href="/" passHref={true}>
        <Image src={logo} alt="icon menu" width={width} height={height} />
      </Link>
    </div>
  );
};

export default Logo;
