import { currentAppointmentSelector } from "../redux/rendezvous-customer-redux/_internal/customer-slice/session/sessionSelectors";
import { useAppSelector } from "../redux/store";

const useCurrentAppointment = () => {
  const currentAppointment = useAppSelector(currentAppointmentSelector);

  return currentAppointment;
};

export default useCurrentAppointment;
