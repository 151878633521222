import { useState } from "react";
import useCurrentAppointment from "../appointment-hooks/useCurrentAppointment";
import * as gtag from "../services/gtag";

const useTrackCtaAppointments = () => {
  const [clicked, setClicked] = useState(false);

  const currentAppointment = useCurrentAppointment();

  const dispatch = () => {
    if (clicked == false) {
      setClicked(true);
      if (currentAppointment) {
        gtag.event({
          action: "cta_button_has_appointment",
          category: "flow",
          label: "cta_button_has_appointment",
          value: 0,
        });
      } else {
        gtag.event({
          action: "cta_button_no_appointment",
          category: "flow",
          label: "cta_button_no_appointment",
          value: 0,
        });
      }
    }
  };
  return dispatch;
};

export default useTrackCtaAppointments;
