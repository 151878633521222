import React, { useState } from "react";
import { VscMenu } from "react-icons/vsc";
import Menu from "../menu/Menu";

const Hamburger: React.FC = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  return (
    <>
      <div className="items-center ml-[5.33px] inline-flex p-4 desktop:hidden">
        <VscMenu
          className="text-2xl text-white"
          onClick={() => {
            setIsMenuVisible(true);
          }}
        />
      </div>

      <Menu
        isMenuVisibleHandler={(value: boolean) => {
          setIsMenuVisible(value);
        }}
        isMenuVisible={isMenuVisible}
      />
    </>
  );
};

export default Hamburger;
