import AppointmentFormState from "./AppointmentFormState";
import { PayloadAction } from "@reduxjs/toolkit";
import { OrderAvailableAppointmentDto } from "../../../service/customerTypes";

export default function selectAppointment(
  state: AppointmentFormState,
  action: PayloadAction<OrderAvailableAppointmentDto>
) {
  const time = action.payload.appointmentTime.substring(
    action.payload.appointmentTime.indexOf("T") + 1
  );

  state.selectedServiceRoom = null;
  state.selectedTime = time;
  state.selectedAppointment = action.payload;
}
