import AppointmentFormState from "./AppointmentFormState";
import { PayloadAction } from "@reduxjs/toolkit";
import { SalonServiceDto } from "../../../service/customerTypes";

export default function selectService(
  state: AppointmentFormState,
  action: PayloadAction<SalonServiceDto>
) {
  state.selectedService = action.payload;
  state.selectedDay = null;
  state.selectedTime = null;
  state.selectedServiceRoom = null;
}
