import AppointmentFormState from "./AppointmentFormState";
import { PayloadAction } from "@reduxjs/toolkit";
import { SalonServiceDto } from "../../../service/customerTypes";

export default function selectServiceRoom(
  state: AppointmentFormState,
  action: PayloadAction<string>
) {
  state.selectedServiceRoom = action.payload;
}
