import {
  MakeOrderDto,
  SalonOrderDto,
} from "./../../../../service/customerTypes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "../../../../../store";
import customerService from "../../../../service/customerService";
import AppointmentFormState from "../AppointmentFormState";

const genericErrorMessage = "Greška, pokušajte ponovo kasnije.";

export const makeAppointment = createAsyncThunk<
  SalonOrderDto | null,
  void,
  { state: AppState }
>(`customer-client/makeAppointment`, async (undefined, thunkApi) => {
  const currentState: AppState = thunkApi.getState();

  const appointmentForm = currentState.customerClient.appointmentForm;
  const customerToken = currentState.customerClient.sessionState.userToken;

  if (customerToken == null) {
    console.error("No customer token");
    return null;
  }

  try {
    const makeOrderRequest = createMakeOrderRequest(appointmentForm);
    if (makeOrderRequest) {
      const response = await customerService.makeOrderAppointment(
        customerToken,
        makeOrderRequest
      );

      if (response.status == 200) {
        return response.data;
      } else {
        console.error(
          `makeOrderRequest returned: ${response.status}, `,
          response.data
        );
      }
    } else {
      console.error(
        "Cannot create order request from appointmentForm: ",
        appointmentForm
      );
    }
  } catch (err) {
    console.error(err);
    window.alert(genericErrorMessage);
  }

  return null;
});

function createMakeOrderRequest(
  appointmentForm: AppointmentFormState
): MakeOrderDto | undefined {
  if (appointmentForm.selectedService && appointmentForm.selectedServiceRoom) {
    return {
      serviceEmployeeOrderPairList: [
        {
          salonEmployees: [],
          salonServices: [appointmentForm.selectedService.salonServiceId],
          serviceRooms: [appointmentForm.selectedServiceRoom],
        },
      ],
      localDateTimeFrom: `${appointmentForm.selectedDay}T${appointmentForm.selectedTime}`,
      name: appointmentForm.name,
      phone: appointmentForm.phone,
      email: appointmentForm.email,
      manualPrice: appointmentForm.manualPrice,
    };
  }

  return undefined;
}

export default makeAppointment;
