export var GA_TRACKING_ID = process.env.GA_TRACKING_ID || undefined;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url, ga_id) => {
  if (window && typeof window.gtag === "function") {
    try {
      window.gtag("config", ga_id, {
        page_path: url,
      });
    } catch (e) {}
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if (window && typeof window.gtag === "function") {
    try {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value,
      });
    } catch (e) {}
  }
};
