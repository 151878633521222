import { SalonOrderDto } from "../../../service/customerTypes";
import SessionStatus from "./SessionStatus";

export default interface SessionState {
  userToken: string | null;
  sessionStatus: SessionStatus;
  currentAppointment: SalonOrderDto | null;
  sessiontStatusRequestId: string | null;
}

export function createInitisalSessionState(): SessionState {
  return {
    userToken: null,
    sessionStatus: SessionStatus.NotStarted,
    currentAppointment: null,
    sessiontStatusRequestId: null,
  };
}
