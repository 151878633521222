import React from "react";
import Hamburger from "./Hamburger";
import Logo from "./Logo";
import NavbarMenu from "./navbar-menu/NavbarMenu";

const Navbar: React.FC = () => {
  return (
    <nav className="bg-navbarTransparent fixed top-0 z-menu w-fullvw ">
      <div className="h-16 w-fullvw desktop:h-[87px] flex items-center justify-between  max-w-container mx-auto">
        <Logo />
        <Hamburger />
        <NavbarMenu />
      </div>
    </nav>
  );
};

export default Navbar;
