import CustomerSliceState from "./CustomerSliceState";
import SessionStatus from "./session/SessionStatus";
import { startCustomerSession } from "./session/startCustomerSession";
import { loadCurrentAppointment } from "./session/currentAppointmentStorage";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

const sessionExtraReducers = (
  builder: ActionReducerMapBuilder<CustomerSliceState>
) => {
  builder.addCase(startCustomerSession.pending, (state, payload) => {
    if (state.sessionState.sessionStatus == SessionStatus.NotStarted) {
      state.sessionState.sessionStatus = SessionStatus.Starting;
      state.sessionState.sessiontStatusRequestId = payload.meta.requestId;
    }
  });

  builder.addCase(startCustomerSession.fulfilled, (state, action) => {
    if (
      action.payload &&
      state.sessionState.sessiontStatusRequestId === action.meta.requestId
    ) {
      state.sessionState.userToken = action.payload;
      state.sessionState.sessionStatus = SessionStatus.Started;
      loadCurrentAppointmentFromStorage(state);
      state.sessionState.sessiontStatusRequestId = null;
    }
  });

  builder.addCase(startCustomerSession.rejected, (state, action) => {
    if (state.sessionState.sessiontStatusRequestId === action.meta.requestId) {
      state.sessionState.sessionStatus = SessionStatus.Failed;
      state.sessionState.sessiontStatusRequestId = null;
    }
  });
};

function loadCurrentAppointmentFromStorage(state: CustomerSliceState) {
  const order = loadCurrentAppointment();
  if (order && order.orderItems.length > 0 && order.orderItems[0].endTime) {
    const orderEndTime = new Date(order.orderItems[0].endTime);
    const currentTime = new Date();

    if (orderEndTime.getTime() > currentTime.getTime()) {
      state.sessionState.currentAppointment = order;
    }
  }
}

export default sessionExtraReducers;
