import axios, { AxiosResponse } from "axios";
import {
  CustomerDto,
  AvailableAppointmentsDto,
  MakeAppointmentDto,
  UserAppointmentDto,
  AvailableSalonServicesDto,
  AvailableAppointmentsForServicesDto,
  MakeOrderDto,
  SalonOrderDto,
} from "./customerTypes";

const baseUrl = process.env.NEXT_PUBLIC_BACKEND_URL + "/v1/customers";

const registerAnonymous = async (): Promise<AxiosResponse<CustomerDto>> => {
  const response = axios.post<CustomerDto>(`${baseUrl}/register-anonymous`);
  return response;
};

const getCustomer = (
  customerToken: string
): Promise<AxiosResponse<CustomerDto>> | Promise<AxiosResponse<string>> => {
  const response = axios.get(`${baseUrl}/${customerToken}`);
  return response;
};

const getAvailableAppointments = (
  customerToken: string
): Promise<AxiosResponse<AvailableAppointmentsDto>> => {
  const response = axios.get(
    `${baseUrl}/${customerToken}/appointments/available`,
    {
      headers: {
        accept: "application/json",
      },
      data: {},
    }
  );
  return response;
};

const makeAppointment = (
  customerToken: string,
  data: MakeAppointmentDto
): Promise<AxiosResponse<MakeAppointmentDto>> => {
  const response = axios({
    method: "post",
    url: `${baseUrl}/${customerToken}/appointments`,
    headers: {
      accept: "application/json",
    },
    data: {
      localDateTimeFrom: data.localDateTimeFrom,
      localDateTimeTo: data.localDateTimeTo,
      name: data.name,
      phone: data.phone,
      email: data.email,
      employeeId: data.employeeId,
    },
  });
  return response;
};

const cancelAppointment = (
  customerToken: string
): Promise<AxiosResponse<MakeAppointmentDto>> => {
  const response = axios({
    method: "post",
    url: `${baseUrl}/${customerToken}/appointments/current/cancel`,
    headers: {
      accept: "application/json",
    },
    data: {},
  });
  return response;
};

const getAppointment = (
  customerToken: string
): Promise<AxiosResponse<UserAppointmentDto>> => {
  const response = axios.get(
    `${baseUrl}/${customerToken}/appointments/current`,
    {
      headers: {
        accept: "application/json",
      },
      data: {},
    }
  );

  return response;
};

const getServices = (
  customerToken: string
): Promise<AxiosResponse<AvailableSalonServicesDto>> => {
  const response = axios.get(`${baseUrl}/${customerToken}/services`, {
    headers: {
      accept: "application/json",
    },
    data: {},
  });

  return response;
};

const getAvailableAppointmentsForServices = (
  customerToken: string,
  services: string[]
): Promise<AxiosResponse<AvailableAppointmentsForServicesDto>> => {
  const response = axios({
    method: "post",
    url: `${baseUrl}/${customerToken}/services/available-appointments`,
    headers: {
      accept: "application/json",
    },
    data: {
      salonServices: services,
    },
  });

  return response;
};

const makeOrderAppointment = (
  customerToken: string,
  makeOrderDto: MakeOrderDto
): Promise<AxiosResponse<SalonOrderDto>> => {
  const response = axios({
    method: "post",
    url: `${baseUrl}/${customerToken}/services/makeorder`,
    headers: {
      accept: "application/json",
    },
    data: makeOrderDto,
  });

  return response;
};

const cancelOrder = (customerToken: string): Promise<AxiosResponse<void>> => {
  const response = axios({
    method: "post",
    url: `${baseUrl}/${customerToken}/services/current/cancel`,
    headers: {
      accept: "application/json",
    },
    data: {},
  });

  return response;
};

const allCustomerServices = {
  registerAnonymous,
  getCustomer,
  getAvailableAppointments,
  makeAppointment,
  cancelAppointment,
  getAppointment,
  getServices,
  getAvailableAppointmentsForServices,
  makeOrderAppointment,
  cancelOrder,
};

export default allCustomerServices;
