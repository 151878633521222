import { AvailableSalonServicesDto } from "./../../../service/customerTypes";

export default interface SalonServicesState {
  services: AvailableSalonServicesDto | null;
  fetchingStatus: "NOT_FETCHED" | "FETCHING" | "FETCHED";
  fetchingRequestId: string | null;
}

export function createInitialSalonServicesState(): SalonServicesState {
  return {
    services: null,
    fetchingStatus: "NOT_FETCHED",
    fetchingRequestId: null,
  };
}
